

.current-position {
  font-size: 0.7rem;
  color: #333;
  margin-bottom: 0.2rem;
}

.name {
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
  .availability {
    font-size: 1rem;
    padding-bottom: 0.15rem;
    vertical-align: middle;
    display: inline-block;
    &[data-availability="AVAILABLE"] {
      color: var(--green-700)
    }
    &[data-availability="TEMP_NOT_AVAILABLE"] {
      color: var(--yellow-700)
    }
    &[data-availability="NOT_AVAILABLE"] {
      color: var(--red-700)
    }
  }
}
.functions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .function {
    font-size: 0.6rem;
    padding: 0.15rem 0.15rem;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;

    &[data-func="ZF"] {
      background-color: var(--green-400, green);
      border-color: var(--green-900);
    }
    &[data-func="GF"] {
      background-color: var(--blue-800);
      border-color: var(--blue-900);
      color: white;
    }
    &[data-func="TF"] {
      background-color: hsl(10, 80%, 57%);
      border-color: hsl(1, 69%, 35%);
      color: white;
    }
    &[data-func="TM"] {
      background-color: hsl(0, 0%, 100%);
    }
    &[data-func="MA"] {
      background-color: var(--yellow-500);
      border-color: var(--yellow-900);
    }
    &[data-func="WLF"] {
      background-color: var(--yellow-500);
      border-color: var(--yellow-900);
    }
    &[data-func="DLK"] {
      background-color: var(--pink-800);
      border-color: var(--pink-900);
      color: white;
    }
    &[data-func="KEIN ATEMSCHUTZ"] {
      border-color: hsl(1, 80%, 57%);
      color: hsl(1, 80%, 57%);
    }
  }
}

.placed {
  color: rgb(144, 144, 144);
}
.hasPosition {
  opacity: 0.8;
  .name {
    text-decoration: line-through;
    font-weight: 300;
  }
}
.highlightWithoutPosition .name {
  font-weight: 800;
  color: var(--green-700);
}
