
body {
  background-color: var(--bluegray-300);
  margin: 0;
}

*:fullscreen,
:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: rgba(255, 255, 255, 0);
}

.menu-end {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
}

div.p-menubar.p-component.offline {
  border-top: 2px solid red;
}
#desktop {
  margin-top: 75px;
}
div.p-menubar.p-component {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #fff;
}

li:hover {
  text-decoration: none;
}
#app1 {
  height: 100vh;
}
