
.page {
  display: grid;
  height: calc(100% - 75px);
  grid-template-columns: 850px minmax(0, 4fr);
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas: "sidebar main";
}

.sidebar {
  grid-area: sidebar;
  padding: 16px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  gap: 16px 16px;
  grid-template-areas: "left right";

  .left {
    grid-area: left;
  }

  .right {
    display: grid;
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    align-content: space-between;
    grid-row-gap: 16px;

    .panel {
      flex-grow: 2;

      &:first-child {
        margin-bottom: 16px;
      }

    }
  }
}

.main {
  overflow: scroll;
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-content: space-between;
  gap: 16px 16px;
  grid-template-areas:
    "vehicles"
    "special-locations";
}

.vehicles {
  grid-area: vehicles;
  overflow-x: scroll;
}

.special-locations {
  grid-area: special-locations;
  grid-area: special-locations;
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  margin-bottom: 16px;
  justify-content: flex-start;
  align-content: stretch;
  min-height: 40vh;

  .location {
    flex-grow: 1;
    max-width: 350px;
    margin: 0 0.5rem 0 0.5rem;
  }

  .gate {
    max-width: 165px;
  }
}

li {
  margin-top: 16px;
}

.filter-button {
  display: flex;

  label {
    margin-right: 0.5rem;
  }
}

li:hover {
  cursor: pointer;
  text-decoration: underline;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
.growing_panel {
  &.p-panel {
    height: 100%;
    display: flex;
    flex-direction: column;

    .p-toggleable-content {
      flex-grow: 1;

      .p-panel-content {
        height: 100%
      }
    }

  }
}

