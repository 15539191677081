

.STATUS_1 {
  box-shadow: 0px 0px 13px 3px var(--blue-800);
}
.STATUS_2 {
  box-shadow: 0px 0px 13px 3px var(--green-800);
}
.STATUS_3 {
  box-shadow: 0px 0px 13px 3px var(--yellow-800);
}

.STATUS_4,
.STATUS_5,
.STATUS_6,
.STATUS_7,
.STATUS_F,
{
  box-shadow: 0px 0px 13px 3px var(--red-800);
}

.STATUS_8, .STATUS_6, {
  background: rgba(255,255,255,0.8);
}

