
.availibility-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  padding-bottom: 5px;
  padding-top: 5px;

  .p-splitbutton.p-component.p-button-sm.p-button-outlined,
  .p-button.p-button-sm {
    font-size: 0.6rem;
    padding: 0.3rem 0.4rem;
  }
  .p-button.p-button-icon-only {
    width: 2rem;
    padding: 0.3rem 0.4rem;
    font-size: 0.6rem;
  }
}
