
.position {
  margin-bottom: 1rem;
}
.header {
  margin-left: 0.3rem;
}
.global-position {
  border: 1px var(--border-style, dashed) var(--border-color, #ccc);
  min-height: 59px;
  min-width: 300px;
  border-radius: 3px;
  margin-bottom: 0.4rem;
  &:hover {
    cursor: pointer;
  }

  &.hasPerson {
    --border-color: var(--green-300);
    --border-style: solid;
    --header-background: var(--green-300);
  }

  .position-name {
    padding: 5px;
    font-size: 0.5rem;
    letter-spacing: 1.5px;
    text-transform: none;
    background-color: var(--header-background, #ccc);
  }

  .position-person {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    .position-person-name {
      min-height: 100%;
      flex-grow: 1;
      font-size: 0.8rem;
    }
  }
}

.list-item {
  border-bottom: 1px solid #333;
  padding: 5px 0 5px 0;
  &:hover {
    cursor: pointer;
  }
  &:only-child {
    border-bottom: none;
  }
}
